import React from 'react';
import logo from './logo.svg';
import './App.css';
import useDfpSlot from './useDfpSlot';

function App() {
  useDfpSlot({
    path: '/18643286/Mahapnchayath_Ads',
    size: [[970,90],[320,50]],
    id: 'div-gpt-ad-5874631-1',
  });

  useDfpSlot({
    path: '/18643286/Mahapnchayath_Ads_2',
    size: [[970,90],[320,50]],
    id: 'div-gpt-ad-6263443-1',
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <div id="div-gpt-ad-5874631-1" />
        <div id="div-gpt-ad-6263443-1" />
      </header>
    </div>
  );
}

export default App;
