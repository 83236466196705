import { useEffect } from 'react';

const useDfpSlot = ({ path, size, id }) => {
  useEffect(() => {
    const googletag = window.googletag || {};

    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(() => {
        const mapping1 = googletag.sizeMapping()
            .addSize([1000, 400], [[970, 90]])
            .addSize([400, 400], [[320, 50]])
            .build();

        googletag.defineSlot(path, size, id)
            .defineSizeMapping(mapping1)
            .addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
        googletag.display(id);
    });
  }, [path, size, id]);
};

export default useDfpSlot;